<template>
  <div class="response success fadein" ref="refResponse" v-show="response">
    {{ response }}
  </div>
  <div class="response error fadein" ref="refError" v-show="errors.length">
    <template v-for="(error, idx) in errors" :key="idx">
      <template v-if="errors.length > 1">
        <li>{{ error }}</li>
      </template>
      <template v-else>
        {{ error }}
      </template>
    </template>
  </div>
  <form
    action="#"
    @submit.prevent="formSubmit"
    class="form"
    data-gw-tab="signup"
    data-gw-tab-grp="form"
    ref="form"
  >
    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__user.png" alt="" />
        </div>
        <div class="field__title">ID</div>
      </div>
      <input
        type="text"
        name="username"
        v-model.trim="form.username"
        class="input"
        minlength="4"
        maxlength="14"
      />
    </div>
    <!-- END  field -->

    <div class="field field_grp field_mb">
      <div class="field__desc">
        <div class="field__ico">
          <img src="@/assets/images/icons/ico__lock.png" alt="Password" />
        </div>
        <div class="field__title">Password</div>
      </div>
      <input
        :type="passwordReveal ? 'text' : 'password'"
        class="input"
        name="password"
        v-model="form.password"
        autocomplete
        minlength="8"
        maxlength="16"
      />
      <div class="field__tool">
        <div class="field__tool-trigger" @click="passwordReveal = !passwordReveal">
          <img :src="passwordRevealIcon" alt="Password reveal" />
        </div>
      </div>
    </div>
    <!-- END  field -->

    <div class="field field_mb text-center login_bottom_links">
      <router-link :to="{ name: 'Signup' }">Create account</router-link> |
      <router-link :to="{ name: 'RestoreUsername' }">Restore ID</router-link> |
      <router-link :to="{ name: 'RestorePassword' }">Restore password </router-link>
    </div>

    <div class="protection protection_mb">
      <div class="protection__captcha">
        <vue-recaptcha
          v-show="showRecaptcha"
          :sitekey="recaptcha_sitekey"
          size="normal"
          theme="dark"
          :tabindex="0"
          @verify="recaptchaVerified"
          @expire="recaptchaExpired"
          @fail="recaptchaFailed"
          ref="vueRecaptcha"
        >
        </vue-recaptcha>
      </div>
    </div>

    <div class="form__btn-box">
      <button
        class="button"
        :class="{ disabled: this.btn_disabled }"
        type="submit"
        :disabled="this.btn_disabled"
      >
        <span class="button__content">Login</span>
      </button>
    </div>
  </form>
</template>

<script>
import { recaptcha_sitekey } from '@/assets/js/recaptcha';
import vueRecaptcha from 'vue3-recaptcha2';
import axios from 'axios';
import cfg_axios from '@/config/cfg_axios';

export default {
  name: 'Login',
  components: {
    vueRecaptcha,
  },
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      errors: [],
      response: null,
      msg_resp: null,
      btn_disabled: false,
      passwordReveal: false,
      recaptcha_sitekey,
      showRecaptcha: true,
      captcha: true,
      toast_opts: {
        position: 'top-right',
      },
    };
  },
  computed: {
    passwordRevealIcon() {
      return require(!this.passwordReveal
        ? '@/assets/images/icons/ico__eye.png'
        : '@/assets/images/icons/ico__eye-off.png');
    },
  },
  beforeMount() {
    try {
      if (this.$store.getters.get_logged_in) {
        this.$router.push({ name: 'Account' });
      }
    } catch (e) {
      console.error('Login [2]', e.message);
    }
  },
  methods: {
    async formSubmit() {
      this.errors = [];
      this.btn_disabled = true;
      this.response = null;

      // try {
      //   localStorage.setItem("test_flag", "x");
      // } catch (e) {
      //   this.errors.push(e.message);
      //   this.errors.push("Possible error source: disabled cookies");
      // }

      if (!this.form.username) {
        this.errors.push('Username cannot be empty');
      } else {
        // if (this.form.username.length < 6) {
        //   this.errors.push("Username length must be at least 6 characters");
        // }
      }

      if (!this.form.password) {
        this.errors.push('Password cannot be empty');
      } else {
        // if (this.form.password.length < 8) {
        //   this.errors.push("Password length must be at least 8 characters");
        // }
      }

      if (!this.captcha) {
        this.errors.push('You should verify captcha');
      }

      if (this.errors.length) {
        setTimeout(() => {
          // clear old errors
          this.errors = [];
          // disable buttons to prevent spam
          this.btn_disabled = false;
          // clear old response on new form submit
          this.response = null;
        }, 5000);
        return;
      }

      // send ajax request to api server end
      // check if username and password combination exists

      const form_data = new FormData(this.$refs.form);

      await axios
        .post(cfg_axios.base_url + '/auth/login', form_data, {
          headers: cfg_axios.headers,
        })
        .then(response => {
          if (response.data) {
            if (response.data.message) {
              this.$store.dispatch('auth_user', response.data);
            } else {
              this.errors.push(response.data);
            }
          }
        })
        .catch(error => {
          if (error.response.data) {
            if (error.response.data.messages) {
              this.errors.push(
                error.response.data.messages[Object.keys(error.response.data.messages)[0]]
              );
            }
          }
        })
        .finally(() => {
          this.recaptchaExpired();
          setTimeout(() => {
            this.btn_disabled = false;
            this.errors = [];
            this.response = null;
          }, 10000);
        });
    },
    recaptchaVerified(recaptcha) {
      this.captcha = recaptcha;
    },
    recaptchaExpired() {
      this.captcha = false;
      this.$refs.vueRecaptcha.reset();
    },
    recaptchaFailed() {
      this.captcha = false;
    },
  },
};
</script>

<style scoped>
.login_bottom_links {
  font-size: 1.25em;
}
</style>
